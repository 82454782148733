import React from 'react';
import * as XLSX from 'xlsx';

interface ExportToExcelProps {
    data: any;
  }
  

const ExportToExcel = ({ data }: ExportToExcelProps) => {
  const handleDownload = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Convert data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);
    
    // Add worksheet to workbook under the name "Sheet1"
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
    // Write workbook and force download
    XLSX.writeFile(workbook, "DataExport.xlsx");
  };

  return (
    <button className="btn btn-primary" onClick={handleDownload}>
      Download Excel
    </button>
  );
};

export default ExportToExcel;
