import React, { useEffect, useState } from 'react';
import ExportToExcel from '../components/ExcelComponent';
import { useUserDetails } from '../hooks/useUserDetails';
import { commonApi } from '../services/api';
import { useNavigate } from 'react-router-dom';
import Table from '../components/Table';

const StudentsList = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const userDetails: any = useUserDetails();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (userDetails && userDetails.provider === 'Schoolofcoding') {
      setShow(true);
    }

    const fetchStudents = async () => {
      try {
        const response = await commonApi.get('/students/getall');
        setStudents(response.data);
        setFilteredStudents(response.data); // Initialize filteredStudents with the complete list
      } catch (error) {
        console.error('Failed to fetch students:', error);
      }
    };

    fetchStudents();
  }, [userDetails]);

  useEffect(() => {
    const filteredData = students.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredStudents(filteredData);
  }, [searchQuery, students]);

  const change = () => {
    navigate('/addstudent');
  };

  const onEdit = (studentId: any) => {
    navigate(`/student/${studentId}/edit`);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <h2 className='mt-1'>Learners List</h2>
          <input
            type="text"
            className='form-control'
            style={{ width: '50%', marginLeft: '25px' }}
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div>
          {show && (
            <ExportToExcel data={filteredStudents} />
          )}
          <button className='btn btn-primary ms-2' onClick={change}>
            Add Learner
          </button>
        </div>
      </div>
      <Table
        data={filteredStudents}
        columns={[
          { key: 'firstName', label: 'First Name' },
          { key: 'lastName', label: 'Last Name' },
        
          { key: 'learningStatus', label: 'Learning Status' },
          { key: 'employmentStatus', label: 'Employment Status' },
          { key: 'provider', label: 'Provider' }
        ]}
        itemsPerPage={15}
        onEdit={onEdit}
      />
    </div>
  );
};

export default StudentsList;
