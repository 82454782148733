import React, { useEffect, useState } from 'react';
import { commonApi } from '../services/api';
import { useNavigate } from 'react-router-dom';
import { useUserDetails } from '../hooks/useUserDetails';
import EmployeeTable from '../components/Table';

const EmployeesList = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const userDetails: any = useUserDetails();

  useEffect(() => {
    if (!userDetails) return;

    const fetchEmployees = async () => {
      try {
        const response = await commonApi.get('/employees/getall');
        const employees = response.data;
        const filteredEmployees = employees.filter((employee: any) => employee.provider === userDetails.provider);
        setEmployees(filteredEmployees);
        setFilteredEmployees(filteredEmployees);
      } catch (error) {
        console.error('Failed to fetch employees:', error);
      }
    };

    fetchEmployees();
  }, [userDetails]);

  useEffect(() => {
    const filteredData = employees.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredEmployees(filteredData);
  }, [searchQuery, employees]);

  const change = () => {
    navigate('/addemployee');
  };

  const onEdit = (employeeId: any) => {
    navigate(`/employee/${employeeId}/edit`);
  };

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='d-flex'>
          <h2 className='mt-1'>Users List</h2>
          <input
            type="text"
            className='form-control'
            style={{ width: '50%', marginLeft: '25px' }}
            placeholder='Search'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div>
          <button className='btn btn-primary ms-2' onClick={change}>Add User</button>
        </div>
      </div>
      <EmployeeTable
        data={filteredEmployees}
        columns={[
          { key: 'firstName', label: 'Name' },
          { key: 'email', label: 'Email' },
          { key: 'provider', label: 'Provider' }
        ]}
        itemsPerPage={15}
        onEdit={onEdit}
      />
    </div>
  );
};

export default EmployeesList;
