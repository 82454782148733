import { useState, useEffect } from 'react';
import { commonApi } from '../services/api';

export const useUserDetails = () => {
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const authToken = localStorage.getItem('token');
        const userResponse = await commonApi.post('/verifyTokenOne', { token: authToken });
        const user = userResponse.data.message;
        setUserDetails(user);
      } catch (error) {
        console.error('Failed to fetch user details:', error);
        setUserDetails(null);
      }
    };

    fetchUserDetails();
  }, []);

  return userDetails;
};
