import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { SignupFormData } from '../interfaces/SignUpFormData'; 
import { commonApi } from '../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import toast from '../components/toast';
import { useUserDetails } from '../hooks/useUserDetails';

const EmployeesFormData: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<SignupFormData>();
  const navigate = useNavigate();


  const { employeeId } = useParams();
  const [action, setAction] = useState(false);
  const userDetails: any = useUserDetails();
  const [show, setShow] = useState(false);
  const [employeeDetail, setEmployeeDetail] = useState([]);
  const [showSchoolOfCoding, setShowSchoolOfCoding] = useState(false);
  const [showShropshireCouncil, setShowShropshireCouncil] = useState(false);
  const [showShropshireChamber, setShowShropshireChamber] = useState(false);

  const onSubmit = async (data: SignupFormData) => {
    try {
      if (action) {
        const response = await commonApi.post(`/employee/${employeeId}/update`, data);
        console.log(" response.data;", response.data)
        toast.notifySuccess('Updated')
        navigate('/employees');
      } else {
        const response = await commonApi.post('employee/signup', data);
        console.log(" response.data;", response.data)
        toast.notifySuccess('Created')
        navigate('/employees');
      }
    } catch (error: any) {
      console.log(error.response.data)
      toast.notifyError(error.response.data)
      // alert('Operation failed: ' + error.message);
    }
  };
  const onClickBack=()=>{
    navigate('/employees');
  };

  const getSingleEmployeeDetails = async (id: any) => {
    try {
      const response = await commonApi.get(`/employee/${id}/get`);
      setEmployeeDetail(response.data)
    } catch (error: any) {
      alert('Registration failed: ' + error.message);
    }
  };


 
  useEffect(() => {
    console.log("sdaghj",userDetails)
    const isSchoolOfCoding = userDetails&& userDetails.provider === 'SchoolofCoding';
    setShowSchoolOfCoding(isSchoolOfCoding);
    setShowShropshireCouncil(isSchoolOfCoding || userDetails&&userDetails.provider === 'SphorshireCouncil');
    setShowShropshireChamber(isSchoolOfCoding || userDetails&&userDetails.provider === 'SphorshireChamber');
   console.log("showSchoolOfCoding",showSchoolOfCoding)
   console.log("showSchoolOfCoding",showShropshireCouncil)
   console.log("showSchoolOfCoding",showShropshireChamber)
    console.log("sss", employeeId)
    if (employeeId) {
      setAction(true)
      getSingleEmployeeDetails(employeeId)
    }
  }, [userDetails])

  useEffect(() => {
    if (employeeDetail) {
      Object.entries(employeeDetail).forEach(([key, value]) => {
        setValue(key as keyof SignupFormData, value);
      });
    }
  }, [employeeDetail, setValue]);


  return (
    <div className="container mt-4">
    <div className="d-flex justify-content-between align-items-center">
    <img src="../../leftArrow.svg" alt="arrow" className="custom-select-height"  onClick={onClickBack}/>
    <div className="flex-grow-1 d-flex justify-content-center">
      <h2 className="text-center">Employee Details</h2>
    </div>
  </div>
      <div className='form'>
      <form onSubmit={handleSubmit(onSubmit)}  >
        <div className="mb-3">
          <label className="form-label">First Name</label>
          <input type="text" {...register('firstName', { required: 'First Name is required' })}
            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
          {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Last Name</label>
          <input type="text" {...register('lastName', { required: 'Last Name is required' })}
            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
          {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input type="email" {...register('email', { required: 'Email is required' })}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <input type="password" {...register('password', { required: 'Password is required' })}
            className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
          {errors.password && <div className="invalid-feedback">{errors.password.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Provider</label>
        <select
           {...register('provider', { required: 'Provider is required' })}
           className={`form-control ${errors.provider ? 'is-invalid' : ''}`}
            disabled={action === true} defaultValue=''
       >
             <option value="" disabled>Select Provider</option>
                {showSchoolOfCoding && <option value="SchoolofCoding">School of Coding</option>}
                 {showShropshireCouncil && <option value="ShropshireCouncil">Shropshire Council</option>}
                 {showShropshireChamber && <option value="ShropshireChamber">Shropshire Chamber</option>}
          </select>
       {errors.provider && <div className="invalid-feedback">{errors.provider.message}</div>}
      </div>
      <button type="submit" className="btn btn-primary">{action ? 'Update' : 'Save'}</button>
        {/* <div className="mb-3">
          <label className="form-label">Provider</label>
          <select
            {...register('provider', { required: 'Provider is required' })}
            className={`form-control ${errors.provider ? 'is-invalid' : ''}`}
          >
            <option value="" disabled>Select Provider</option>
            <option value="Schoolofcoding">School of Coding </option>
            <option value="ShropshireCouncil">Shropshire Council</option>
            <option value="ShropshireChamber">Shropshire Chamber</option>
          </select>
          {errors.provider && <div className="invalid-feedback">{errors.provider.message}</div>}
        </div>
        <button type="submit" className="btn btn-primary">{action ? 'Update' : 'Save'}</button> */}
      </form>
      </div>
    </div>
  );
};

export default EmployeesFormData;
