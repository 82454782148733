import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { UserFormData } from '../interfaces/UserFormData';
import { commonApi } from '../services/api';
import { useNavigate, useParams } from 'react-router-dom';
import toast from '../components/toast';
import { useUserDetails } from '../hooks/useUserDetails';

const StudentForm: React.FC = () => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm<UserFormData>();

  const navigate = useNavigate();
  const { studentId } = useParams();
  const [action, setAction]: any = useState(false);
  const userDetails: any = useUserDetails();
  const [studentDetail, setStudentDetail] = useState([])
  const [showSchoolOfCoding, setShowSchoolOfCoding] = useState(false);
  const [showShropshireCouncil, setShowShropshireCouncil] = useState(false);
  const [showShropshireChamber, setShowShropshireChamber] = useState(false);
  const [show, setShow] = useState(false);


  const onSubmit = async (data: UserFormData) => {
    try {
      if (action) {
        const response = await commonApi.post(`/student/${studentId}/update`, data);
        console.log(" response.data;", response.data)
        toast.notifySuccess('Updated')
        navigate('/students');
      } else {
        const response = await commonApi.post('/create/student', data);
        console.log(" response.data;", response.data)
        toast.notifySuccess('Created')
        navigate('/students');
      }
    } catch (error: any) {
      console.log(error.response.status)
      if(error.response.status){
        toast.notifyError("User Exists Already")
      }else{
        toast.notifyError(error.response.data)
      }
     

    }
  };
  const onClickBack=()=>{
    navigate('/students');
  };
  const getSingleStudentDetails = async (id: any) => {
    try {
      const response = await commonApi.get(`/student/${id}/get`);
      console.log("ghbn", response)
      const formattedData = {
        ...response.data,
        dob: new Date(response.data.dob).toISOString().split('T')[0]
      };

      setStudentDetail(formattedData)
    } catch (error: any) {
      alert('Registration failed: ' + error.message);
    }
  };

  useEffect(() => {
    // if (userDetails && userDetails.provider === 'Schoolofcoding') {
    //   setShow(true);
    // }
    const isSchoolOfCoding = userDetails&& userDetails.provider === 'SchoolofCoding';
    setShowSchoolOfCoding(isSchoolOfCoding);
    setShowShropshireCouncil(isSchoolOfCoding || userDetails&&userDetails.provider === 'SphorshireCouncil');
    setShowShropshireChamber(isSchoolOfCoding || userDetails&&userDetails.provider === 'SphorshireChamber')
    console.log("sss", studentId)
    if (studentId) {
      setAction(true)
      getSingleStudentDetails(studentId)
    }
  }, [userDetails])
  useEffect(() => {
    if (studentDetail) {

      Object.entries(studentDetail).forEach(([key, value]) => {
        setValue(key as keyof UserFormData, value);
      });
    }
  }, [studentDetail, setValue]);

  return (
    <div className="container mt-4">
 <div className="d-flex justify-content-between align-items-center">
    <img src="../../leftArrow.svg" alt="arrow" className="custom-select-height"  onClick={onClickBack}/>
    <div className="flex-grow-1 d-flex justify-content-center">
      <h2 className="text-center">Learner Details</h2>
    </div>
  </div>
      <div className='form'>
      <form onSubmit={handleSubmit(onSubmit)} >
        <div className="mb-3">
          <label className="form-label">First Name</label>
          <input type="text" {...register('firstName', { required: 'First Name is required' })}
            className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
          {errors.firstName && <div className="invalid-feedback">{errors.firstName.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Last Name</label>
          <input type="text" {...register('lastName', { required: 'Last Name is required' })}
            className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
          {errors.lastName && <div className="invalid-feedback">{errors.lastName.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Date of Birth</label>
          <input
            type="date"
            {...register('dob', { required: 'Date of Birth is required' })} // Provide configuration options
            className={`form-control ${errors.dob ? 'is-invalid' : ''}`}
          />
          {errors.dob && <div className="invalid-feedback">{errors.dob.message}</div>}

        </div>
        <div className="mb-3">
          <label className="form-label">Post Code</label>
          <input type="text" {...register('postCode', { required: 'Post Code is required' })}
            className={`form-control ${errors.postCode ? 'is-invalid' : ''}`} />
          {errors.postCode && <div className="invalid-feedback">{errors.postCode.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Phone Number</label>
          <input type="number" {...register('phoneNumber', { required: 'Phone Number is required' })}
            className={`form-control ${errors.phoneNumber ? 'is-invalid' : ''}`} />
          {errors.phoneNumber && <div className="invalid-feedback">{errors.phoneNumber.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Email Address</label>
          <input type="email" {...register('email', { required: 'Email is required' })}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
          {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Learning Status</label>
          <select {...register('learningStatus', { required: 'Learning Status is required' })}
            className={`form-control ${errors.learningStatus ? 'is-invalid' : ''}`}>
            <option value="">Select Status</option>
            <option value="Completed">Completed</option>
            <option value="Inprogress">Inprogress</option>
            <option value="Notenrolled">Notenrolled</option>
          </select>
          {errors.learningStatus && <div className="invalid-feedback">{errors.learningStatus.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Employment Status</label>
          {/* <select {...register('employmentStatus', { required: 'Provider is required' })}
            className={`form-control ${errors.provider ? 'is-invalid' : ''}`}>
            <option value="disabled">Select Provider</option>
            <option value="Employed">Employed</option>
            <option value="Unemployed">Unemployed</option>
          </select> */}
           <select
        {...register('employmentStatus', { required: 'Employment status is required' })}
        className={`form-control ${errors.employmentStatus ? 'is-invalid' : ''}`}
        defaultValue=""  // Ensures "Select Provider" is the default value
      >
        <option value="" disabled>Select Status</option>
        <option value="Employed">Employed</option>
        <option value="Unemployed">Unemployed</option>
      </select>
     
          {errors.employmentStatus && <div className="invalid-feedback">{errors.employmentStatus.message}</div>}

        </div>
        <div className="mb-3">
          <label className="form-label">Provider</label>
          <select {...register('provider', { required: 'Provider is required' })}
            className={`form-control ${errors.provider ? 'is-invalid' : ''}`} defaultValue=''>
              <option value="" disabled>Select Provider</option>
                {showSchoolOfCoding && <option value="SchoolofCoding">School of Coding</option>}
                 {showShropshireCouncil && <option value="ShropshireCouncil">Shropshire Council</option>}
                 {showShropshireChamber && <option value="ShropshireChamber">Shropshire Chamber</option>}
              </select>
          {errors.provider && <div className="invalid-feedback">{errors.provider.message}</div>}
        </div>
        <div className="mb-3">
          <label className="form-label">Enrolled Course</label>
          <input type="text" {...register('enrolledCourse', { required: 'Course is required' })}
            className={`form-control ${errors.enrolledCourse ? 'is-invalid' : ''}`} />
          {errors.enrolledCourse && <div className="invalid-feedback">{errors.enrolledCourse.message}</div>}
        </div>
        <button type="submit" className="btn btn-primary">{action ? 'Update' : 'Save'}</button>
      </form>
      </div>
    </div>
  );
};

export default StudentForm


