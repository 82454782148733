import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../features/Login';
import UserForm from "../features/LearnerFormData"; 
import AuthenticationCheck from "./authenticationcheck";
import UsersList from "../features/LearnerList";
import EmployeesFormData from "../features/UserFormData";
import EmployeeList from '../features/UserList';
import AuthenticatedLayout from '../Layouts/AuthorisedLayout';
import UnauthenticatedLayout from '../Layouts/UnAuthorizedLayout';

const Router = () => {
  const isLoggedIn = !!localStorage.getItem('token');

  return (
    <Routes>
      {/* Unauthenticated Routes */}
      <Route element={<UnauthenticatedLayout />}>
        {!isLoggedIn && <Route path="/" element={<Login />} />}
        {!isLoggedIn && <Route path="*" element={<Navigate to="/" />} />} {/* Redirect to login if not authenticated */}
      </Route>
      
      {/* Authenticated Routes */}
      <Route element={<AuthenticatedLayout />}>
        {isLoggedIn && <Route path="/addemployee" element={<AuthenticationCheck><EmployeesFormData /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="/addstudent" element={<AuthenticationCheck><UserForm /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="/students" element={<AuthenticationCheck><UsersList /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="/student/:studentId/edit" element={<AuthenticationCheck><UserForm /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="/employees" element={<AuthenticationCheck><EmployeeList /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="/employee/:employeeId/edit" element={<AuthenticationCheck><EmployeesFormData /></AuthenticationCheck>} />}
        {isLoggedIn && <Route path="*" element={<Navigate to="/students" />} />} {/* Redirect to default authenticated route */}
      </Route>
    </Routes>
  );
};

export default Router;
