import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginForm } from '../interfaces/LoginForm';
import { commonApi } from '../services/api';
import { useNavigate } from 'react-router-dom';
import toast from '../components/toast';

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginForm>();

  const navigate = useNavigate();
  const handleLogin: SubmitHandler<LoginForm> = async (data) => {
    try {
      console.log(data);
      const response: any = await commonApi.post('/login', data);
      console.log("sss", response);
      if (response.status === 200) {
        localStorage.setItem('token', response.data);
        window.location.href = '/students';
      } else {
        alert('Invalid username or password');
        toast.notifyError('Invalid username or password');
      }
    } catch (error) {
      toast.notifyError('Invalid username or password');
    }
  };

  return (
  
      <div className='a'>
        <div className='d-flex w-100 h-full b'>
          <div className='col-md-6  d-flex justify-content-center align-items-center flex-column imagelayout'>
            <img src="../coding.png" alt="Coding" className='mt-2' style={{ height: '170px', width: '228px' }} />
            <img src="../main.png" alt="Main" className='mt-2' style={{ height: '170px', width: '228px' }} />
            <img src="../logo1.png" alt="Logo" className='mt-2' style={{ height: '69px', width: '392px' }} />
          </div>
          <hr className='custom-hrs'/>
         
          <div className='col-md-6 d-flex justify-content-center layoutlogin flex-column formlayout'>
          <h4>Welcome, Please Login below</h4>
           <div className='c'>
           <form onSubmit={handleSubmit(handleLogin)} className='mb-3'>
              <div className='mb-3'>
                <label htmlFor="email" className='form-label'>Email:</label>
                <input
                  id="email"
                  type="email"
                  {...register('email', {
                    required: 'Please enter a valid email address',
                  })}
                  className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                  style={{ height: '50px' }}
                />
                {errors.email && <div className='invalid-feedback'>{errors.email.message}</div>}
              </div>
              <div className='mb-3'>
                <label htmlFor="password" className='form-label'>Password:</label>
                <input
                  id="password"
                  type="password"
                  {...register('password', {
                    required: 'Please enter your password',
                  })}
                  className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                  style={{ height: '50px' }}
                />
                {errors.password && <div className='invalid-feedback'>{errors.password.message}</div>}
              </div>
              <div className='d-flex justify-content-center'>
                <button
                  type="submit"
                  className='btn btn-primary mt-2'
                  disabled={isSubmitting}
                  style={{ width: '50%' }}
                >
                  {isSubmitting ? 'Logging in...' : 'Login'}
                </button>
              </div>
            </form>
           </div>
          </div>
      
      </div>
    </div>
  );
}

export default Login;

